import NotificationsSettingsIcon from '@mui/icons-material/AddAlert';
import { NotificationsSettingsList } from './NotificationsSettingsList';
import { NotificationsSettingsEdit } from './NotificationsSettingsEdit';
import { NotificationsSettingsCreate } from './NotificationsSettingsCreate';

export default {
    icon: NotificationsSettingsIcon,
    list: NotificationsSettingsList,
    create: NotificationsSettingsCreate,
    edit: NotificationsSettingsEdit,
};
