import React, { FC, useEffect, useState } from 'react';
import {
    Show,
    TextField,
    Datagrid,
    TabbedShowLayout,
    Tab,
    Pagination,
    ReferenceManyField,
    BooleanField,
    DateField,
    ReferenceField,
    BooleanInput,
    SelectInput,
    required,
    AutocompleteInput,
    ReferenceInput,
    useEditContext,
    ShowButton,
    useDataProvider,
    FunctionField,
} from 'react-admin';

import { RunConfigShow, ConfigShow } from './Buttons';
import { EditableDatagrid, RowForm } from '@react-admin/ra-editable-datagrid';
import ToggleOnIcon from '@mui/icons-material/ToggleOnTwoTone';
import ToggleOffIcon from '@mui/icons-material/ToggleOffTwoTone';
import { Link } from 'react-router-dom';

export const RapidPortfolioLaunchShow = () => {
    return (
        <Show>
            <TabbedShowLayout>
                <Tab label="Summary">
                    <TextField source="id" />
                    <TextField source="code" />
                    <TextField source="description" />
                </Tab>
                <Tab label="Rapid Traders">
                    <ReferenceManyField
                        label="Rapid Traders"
                        reference="rapid_trader"
                        target="rapid_portfolio_launch_id"
                        pagination={<Pagination />}
                    >
                        <RapidTraderDataGrid />
                    </ReferenceManyField>
                </Tab>
                <Tab label="Config">
                    <ConfigShow />
                </Tab>
                <Tab label="Run Config">
                    <RunConfigShow />
                </Tab>
                <Tab label="Journal">
                    <ReferenceManyField
                        reference="rapid_portfolio_launch_pod_events"
                        target="rapid_portfolio_launch_id"
                        label={false}
                        pagination={<Pagination />}
                    >
                        <Datagrid bulkActionButtons={false}>
                            <TextField source="id" />
                            <TextField label="Event type" source="event.type" />
                            <DateField showTime source="event.created_at" />
                        </Datagrid>
                    </ReferenceManyField>
                </Tab>
            </TabbedShowLayout>
        </Show>
    );
};

const FilterButton = () => {
    const { record } = useEditContext();
    return (
        <ReferenceInput
            label="Actual contract"
            source="actual_contract_id"
            reference="instrument_mapping"
            filter={{
                instrument_group: record.instrument_group,
                exante_switch_date_after: new Date(
                    new Date().setHours(0, 0, 0, 0)
                ),
            }}
            perPage={300}
        >
            <AutocompleteInput
                filterToQuery={searchText => ({
                    instrument_code: searchText,
                })}
                optionText="instrument_code"
                optionValue="id"
                sx={{ width: '250px' }}
            />
        </ReferenceInput>
    );
};

interface RapidTraderRowEditProps {
    notificationSettings: Record<string, any>;
}

const RapidTraderRowEdit: FC<RapidTraderRowEditProps> = ({
    notificationSettings,
}) => {
    const { record } = useEditContext();

    return (
        <RowForm>
            <TextField source="id" />
            <TextField source="instrument_group" />
            <FilterButton />
            <ReferenceField
                label="Rapid Portfolio Status"
                source="rapid_portfolio_launch_id"
                reference="rapid_portfolio_launch"
                link={false}
                sortable={false}
            >
                <TextField source="status" />
            </ReferenceField>
            <BooleanInput source="dry_mode" />
            <SelectInput
                label="Tactic mode"
                source="tactic_mode"
                choices={[
                    { id: 'auto', name: 'Auto' },
                    { id: 'profit', name: 'Profit' },
                    { id: 'stop', name: 'Stop' },
                    { id: 'main', name: 'Main' },
                ]}
                validate={required()}
            />
            <Link
                to={
                    notificationSettings[record.id]
                        ? `/rapid_trader_notifications_settings/${
                              notificationSettings[record.id].id
                          }/edit`
                        : `/rapid_trader_notifications_settings/create?trader_id=${record.id}`
                }
            >
                {notificationSettings
                    ? 'Edit notification settings'
                    : 'Create notification settings'}
            </Link>
        </RowForm>
    );
};

const TrueIcon = () => {
    return <ToggleOnIcon color="success" fontSize="large" />;
};

const FalseIcon = () => {
    return <ToggleOffIcon color="error" fontSize="large" />;
};

const RapidTraderDataGrid = props => {
    const dataProvider = useDataProvider();
    const [notificationSettings, setNotificationSettings] = useState({});

    useEffect(() => {
        const fetchNotificationSettings = async () => {
            const { data } = await dataProvider.getList(
                'rapid_trader_notifications_settings',
                {
                    pagination: { page: 1, perPage: 1000 },
                    sort: { field: 'id', order: 'ASC' },
                    filter: {},
                }
            );

            const settingsByTraderId = data.reduce((acc, setting) => {
                acc[setting.rapid_trader] = setting;
                return acc;
            }, {});

            setNotificationSettings(settingsByTraderId);
        };

        fetchNotificationSettings();
    }, [dataProvider]);

    return (
        <EditableDatagrid
            {...props}
            bulkActionButtons={false}
            rowClick="edit"
            data-testid="RapidTraderRowEditId"
            editForm={
                <RapidTraderRowEdit
                    notificationSettings={notificationSettings}
                />
            }
            noDelete
            mutationMode="pessimistic"
        >
            <TextField source="id" />
            <TextField source="instrument_group" />
            <ReferenceField
                label="Actual contract"
                source="actual_contract_id"
                reference="instrument_mapping"
                link={false}
                sortable={false}
            >
                <TextField source="instrument_code" />
            </ReferenceField>
            <ReferenceField
                label="Rapid Portfolio Status"
                source="rapid_portfolio_launch_id"
                reference="rapid_portfolio_launch"
                link={false}
                sortable={false}
            >
                <TextField source="status" />
            </ReferenceField>
            <BooleanField
                source="dry_mode"
                TrueIcon={TrueIcon}
                FalseIcon={FalseIcon}
                {...props}
            />
            <TextField source="tactic_mode" />
            <FunctionField
                label="Notification Settings"
                render={record => {
                    if (
                        !record ||
                        !notificationSettings ||
                        !notificationSettings[record.id]
                    ) {
                        return (
                            <Link
                                to={`/rapid_trader_notifications_settings/create?trader_id=${record.id}`}
                            >
                                Create notification settings
                            </Link>
                        );
                    }

                    const notificationSetting = notificationSettings[record.id];
                    return notificationSetting ? (
                        <div
                            style={{ display: 'flex', flexDirection: 'column' }}
                        >
                            <div
                                style={{ display: 'flex', marginBottom: '5px' }}
                            >
                                <span style={{ fontWeight: 'bold' }}>
                                    Abs position limit:{' '}
                                </span>
                                <span>
                                    {' '}
                                    {
                                        notificationSetting.abs_position_limit
                                    }{' '}
                                </span>
                            </div>
                            <div>
                                <span style={{ fontWeight: 'bold' }}>
                                    Users to notify:{' '}
                                </span>
                                <span>
                                    {notificationSetting.users_to_notify}
                                </span>
                            </div>
                        </div>
                    ) : (
                        <span>No notifications settings</span>
                    );
                }}
            />
            <ShowButton />
        </EditableDatagrid>
    );
};
