import React, { FC } from 'react';
import {
    TextField,
    TopToolbar,
    CreateButton,
    Datagrid,
    EditButton,
    List,
    BulkDeleteButton,
    NumberField,
    DeleteButton,
} from 'react-admin';

import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

const NotificationsSettingsListActions: FC = () => {
    return (
        <>
            <TopToolbar>
                <CreateButton />
            </TopToolbar>
        </>
    );
};

export const NotificationsSettingsList = () => {
    return (
        <List
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<NotificationsSettingsListActions />}
            sort={{ field: 'id', order: 'Desc' }}
            hasCreate
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField label="Id" source="id" sortable={false} />
                <TextField
                    label="Rapid trader"
                    source="rapid_trader"
                    sortable={false}
                />
                <NumberField
                    label="Absolute position limit"
                    source="abs_position_limit"
                    sortable={false}
                />
                <TextField
                    label="Users to notify"
                    source="users_to_notify"
                    sortable={false}
                />
                <EditButton />
                <DeleteButton />
            </Datagrid>
        </List>
    );
};
