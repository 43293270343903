import React, { FC } from 'react';
import {
    TextField,
    TextInput,
    TopToolbar,
    CreateButton,
    Datagrid,
    DateField,
    EditButton,
    List,
    FilterButton,
    usePermissions,
    BulkDeleteButton,
} from 'react-admin';

import CallSplitIcon from '@mui/icons-material/CallSplit';
import CustomPagination, { CustomPerPage } from '../common/custom_pagination';

export const InstrumentMappingIcon = CallSplitIcon;
const listFilters = [
    <TextInput
        key="instrument_code"
        label="Instrument Code"
        source="instrument_code"
        alwaysOn
    />,
    <TextInput
        key="instrument_group"
        label="Instrument Group"
        source="instrument_group"
        alwaysOn
    />,
    <TextInput key="saxo_code" label="Saxo Code" source="saxo_code" />,
    <TextInput key="exante_code" label="Exante Code" source="exante_code" />,
    <TextInput
        key="interactive_brokers_code"
        label="IB Code"
        source="interactive_brokers_code"
    />,
    <TextInput
        key="trading_view_code"
        label="TV Code"
        source="trading_view_code"
    />,
    <TextInput key="cqg_code" label="CQG Code" source="cqg_code" />,
    <TextInput
        key="refinitiv_code"
        label="Refinitiv Code"
        source="refinitiv_code"
    />,
    <TextInput key="cme_code" label="CME Code" source="cme_code" />,
    <TextInput
        key="finam_code"
        label="Finam Code"
        source="finam_code__icontains"
    />,
    <TextInput key="binance_code" label="Binance Code" source="binance_code" />,
    <TextInput key="cbrf_code" label="CBRF Code" source="cbrf_code" />,
    <TextInput key="aarna_code" label="AARNA Code" source="aarna_code" />,
    <TextInput
        key="barchart_code"
        label="Barchart Code"
        source="barchart_code"
    />,
];

const ExanteInstrumentListActions: FC = () => {
    const { permissions } = usePermissions();
    return (
        <>
            <TopToolbar>
                <FilterButton />
                {permissions && permissions.includes('developer') && (
                    <CreateButton />
                )}
            </TopToolbar>
        </>
    );
};

export const InstrumentMappingList = () => {
    const { permissions } = usePermissions();
    return (
        <List
            filters={listFilters}
            pagination={<CustomPagination />}
            perPage={CustomPerPage}
            actions={<ExanteInstrumentListActions />}
            sort={{ field: 'instrument_code', order: 'ASC' }}
            empty={false}
            hasCreate
        >
            <Datagrid
                bulkActionButtons={
                    <BulkDeleteButton mutationMode="pessimistic" />
                }
            >
                <TextField label="Instrument Code" source="instrument_code" />
                <TextField label="Instrument Group" source="instrument_group" />
                <DateField label="Switch Date" source="exante_switch_date" />
                <TextField label="Saxo Code" source="saxo_code" />
                <TextField label="Exante Code" source="exante_code" />
                <TextField label="IB Code" source="interactive_brokers_code" />
                <TextField label="TV Code" source="trading_view_code" />
                <TextField label="CQG Code" source="cqg_code" />
                <TextField label="Refinitiv Code" source="refinitiv_code" />
                <TextField label="CME Code" source="cme_code" />
                <TextField label="Finam Code" source="finam_code" />
                <TextField label="Binance Code" source="binance_code" />
                <TextField label="CBRF Code" source="cbrf_code" />
                <TextField label="AARNA Code" source="aarna_code" />
                <TextField label="Barchart Code" source="barchart_code" />
                {permissions && permissions.includes('developer') && (
                    <EditButton />
                )}
            </Datagrid>
        </List>
    );
};
