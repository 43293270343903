import React from 'react';
import {
    DateInput,
    regex,
    required,
    SimpleForm,
    TextInput,
    Create,
} from 'react-admin';

const validateNoSpaces = regex(
    /^[a-zA-Z0-9_]+(?:[\s\.]+[a-zA-Z0-9_]+)*$/,
    'Remove trailing spaces'
);

export const InstrumentMappingCreate = () => (
    <Create transform={transform}>
        <SimpleForm>
            <TextInput
                source="instrument_code"
                validate={[required(), validateNoSpaces]}
            />
            <TextInput
                source="instrument_group"
                validate={[required(), validateNoSpaces]}
            />
            <DateInput
                label="Switch Date"
                source="exante_switch_date"
                inputProps={{ min: '1000-01-01', max: '9999-01-01' }}
            />
            <TextInput source="saxo_code" validate={validateNoSpaces} />
            <TextInput source="exante_code" validate={validateNoSpaces} />
            <TextInput
                source="interactive_brokers_code"
                validate={validateNoSpaces}
            />
            <TextInput source="trading_view_code" validate={validateNoSpaces} />
            <TextInput source="cqg_code" validate={validateNoSpaces} />
            <TextInput source="refinitiv_code" validate={validateNoSpaces} />
            <TextInput source="cme_code" validate={validateNoSpaces} />
            <TextInput source="finam_code" validate={validateNoSpaces} />
            <TextInput source="binance_code" validate={validateNoSpaces} />
            <TextInput source="cbrf_code" validate={validateNoSpaces} />
            <TextInput source="aarna_code" validate={validateNoSpaces} />
            <TextInput source="barchart_code" validate={validateNoSpaces} />
        </SimpleForm>
    </Create>
);

const transform = data => ({
    instrument_code: data.instrument_code,
    instrument_group: data.instrument_group,
    exante_switch_date:
        data.exante_switch_date == '' ? null : data.exante_switch_date,
    saxo_code: data.saxo_code == '' ? null : data.saxo_code,
    exante_code: data.exante_code == '' ? null : data.exante_code,
    interactive_brokers_code:
        data.interactive_brokers_code == ''
            ? null
            : data.interactive_brokers_code,
    trading_view_code:
        data.trading_view_code == '' ? null : data.trading_view_code,
    cqg_code: data.cqg_code == '' ? null : data.cqg_code,
    refinitiv_code: data.refinitiv_code == '' ? null : data.refinitiv_code,
    cme_code: data.cme_code == '' ? null : data.cme_code,
    finam_code: data.finam_code == '' ? null : data.finam_code,
    binance_code: data.binance_code == '' ? null : data.binance_code,
    cbrf_code: data.cbrf_code == '' ? null : data.cbrf_code,
    aarna_code: data.aarna_code == '' ? null : data.aarna_code,
    barchart_code: data.barchart_code == '' ? null : data.barchart_code,
});
