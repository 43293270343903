import React, { useEffect, useState } from 'react';
import {
    required,
    SimpleForm,
    TextInput,
    Create,
    NumberInput,
    SelectInput,
    useDataProvider,
} from 'react-admin';
import { useSearchParams } from 'react-router-dom';

export const NotificationsSettingsCreate = () => {
    const dataProvider = useDataProvider();
    const [traders, setTraders] = useState<{ id: any; name: any }[]>([]);
    const [searchParams, _] = useSearchParams();

    useEffect(() => {
        const fetchTraders = async () => {
            let page = 1;
            const perPage = 500;
            const sort = { field: 'id', order: 'DESC' };
            const filter = {};

            // Больше 1000 последних трейдеров нам не нужно
            const LIMIT = 1000;

            let data: {
                id: number;
                actual_contract_id?: number;
                dry_mode: boolean;
                instrument_group: string;
                rapid_portfolio_launch_id: number;
                tactic_mode: string;
            }[] = [];

            let done = false;
            while (!done) {
                const res = await dataProvider.getList('rapid_trader', {
                    pagination: { page, perPage },
                    sort,
                    filter,
                });
                data = data.concat(res.data);
                if (
                    res === undefined ||
                    res.data.length < perPage ||
                    data.length >= LIMIT
                ) {
                    done = true;
                }
                page++;
            }

            setTraders(
                data.map(trader => ({
                    id: trader.id,
                    name:
                        trader.id +
                        ' - ' +
                        trader.instrument_group +
                        ' - launch_id - ' +
                        trader.rapid_portfolio_launch_id +
                        ' - dry mode -' +
                        trader.dry_mode,
                }))
            );
        };
        fetchTraders();
    }, [dataProvider]);

    return (
        <Create
            redirect="list"
            transform={data => {
                // remove all commas and split by one or more spaces
                if (typeof data.users_to_notify === 'string') {
                    data.users_to_notify = data.users_to_notify
                        .replace(/,/g, '')
                        .split(/\s+/)
                        .map(user => user.trim())
                        .filter(user => user);
                }

                return data;
            }}
        >
            <SimpleForm>
                <SelectInput
                    source="rapid_trader"
                    choices={traders}
                    validate={required()}
                    defaultValue={searchParams.get('trader_id')}
                />
                <NumberInput
                    source="abs_position_limit"
                    validate={(value: number) =>
                        value < 0 ? 'Must be greater than 0' : undefined
                    }
                />
                <TextInput
                    source="users_to_notify"
                    helperText="Example: @biba @boba"
                />
            </SimpleForm>
        </Create>
    );
};
